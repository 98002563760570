import { graphql } from '~graphqlTypeScript';

export const PRODUCTS = graphql(`
  query products (
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id_In: [ID]
    $id: ID
    $brand_Id_In: [ID]
    $brand_Id: ID
    $orderBy: String
    $search: String
  ) {
    product {
      list (
        offset: $offset
        before: $before
        after: $after
        first: $first
        last: $last
        id_In: $id_In
        id: $id
        brand_Id_In: $brand_Id_In
        brand_Id: $brand_Id
        orderBy: $orderBy
        search: $search
      ) {
          pageInfo {
            ... PageInfoFragment
          }
          edges {
            cursor
            node {
              ... ProductFragment
            }
          }
      }
    }
  }
`);
