import { graphql } from '~graphqlTypeScript';

export const CAMPAIGN_DETAIL = graphql(`
  query campaignDetails(
    $campaignId: ID!
  ) {
    campaignResource {
      get(
        id: $campaignId
      ){
        ... CampaignFragment
        status {
          enum
          display
        }
        brand {
          ... BrandFragment
        }
        campaignmediaSet {
          ... CampaignMediaFragment
        }
        campaignpriceproductSet {
          ... CampaignPriceProductFragment
          product {
            ... ProductFragment
          }
        }
        campaignproductsSet {
          ... CampaignProductFragment
          product {
            ... ProductFragment
          }
          relations {
            ... CampaignProductRelationFragment
          }
        }
        preUploadRequirements {
          ... PreUploadRequirementFragment
        }
        campaigncampaignadditionalconditionSet {
          ... CampaignCampaignAdditionalConditionFragment
          condition {
            ... CampaignAdditionalConditionFragment
          }
        }
        contentrequirementSet {
          ... ContentRequirementFragment
          references {
            ... CampaignReferenceFragment
          }
          preUploadRequirements {
            ... PreUploadRequirementFragment
          }
          postType {
            ... PostTypeFragment
          }
        }
        campaignquestionSet {
          ... CampaignQuestionFragment
        }
        campaigninfluencercharacterization {
          ... CampaignInfluencerCharacterizationFragment
          niches {
            ... InfluencerNicheFragment
          }
          states {
            ... StateFragment
          }
          country {
            ... CountryFragment
          }
          followers {
            ... FollowersCharacterizationFragment
          }
          genders {
            ... GenderFragment
          }
        }
      }
    }
  }
`);

export const CAMPAIGNS_DASHBOARD = graphql(`
  query campaignDashboard(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id_In: [ID]
    $id: ID
    $ambassador_Isnull: Boolean
    $endDate_Gte: Date
    $endDate_Lte: Date
    $endDate_Range: [Date]
    $brand_Id: ID
    $search: String
    $orderBy: String
    $status: MontuCampaignStatusChoices
  ) {
    campaignResource {
      list (
        offset: $offset
        before: $before
        after: $after
        first: $first
        last: $last
        id_In: $id_In
        id: $id
        ambassador_Isnull: $ambassador_Isnull
        endDate_Gte: $endDate_Gte
        endDate_Lte: $endDate_Lte
        endDate_Range: $endDate_Range
        brand_Id: $brand_Id
        search: $search
        orderBy: $orderBy
        status: $status
      ) {
        totalCount
        totalPages
        pageInfo {
          ... PageInfoFragment
        }
        edges {
          cursor
          node {
            ... CampaignFragment
            campaignmediaSet {
              ... CampaignMediaFragment
            }
          }
        }
      }
    } 
  }
`);

export const PRE_UPLOAD_REQUIREMENTS = graphql(`
  query preUploadRequirements {
    generic {
      preUploadRequirements {
        ... PreUploadRequirementFragment
      }
    }
  }
`);

export const CAMPAIGN_PRODUCT_RELATIONS = graphql(`
  query campaignProductRelations {
    generic {
      campaignProductRelations {
        ... CampaignProductRelationFragment
      }
    }
  }
`);

export const CAMPAIGN_QUERY_VARIABLES = graphql(`
  query campaignQueryVariables (
    $countryCodeIn: [String!]
  ) {
    generic {
      ageCharacterization {
        id
        minAge
        maxAge
        display
      }
      campaignProductRelations {
        ... CampaignProductRelationFragment
      }
      postTypes {
        ... PostTypeFragment
      }
      preUploadRequirements {
        ... PreUploadRequirementFragment
      }
      genders {
        ... GenderFragment
      }
      influencerNiches {
        ... InfluencerNicheFragment
      }
      followersCharacterization {
        ... FollowersCharacterizationFragment
      }
    }
    countryCity {
      countries(code_In: $countryCodeIn) {
        ... CountryFragment
      }
    }
  }
`);

export const LAST_CAMPAIGN_REPORT_FILE = graphql(`
  query lastCampaignReportFile (
    $campaignId: String!
    $fileType: CampaignReportFileTypeEnum!
  ) {
    campaignResource {
      lastCampaignReport (
        campaignId: $campaignId
        fileType: $fileType
      ) {
        ... CampaignReportFileTypeFragment
      }
    }
  }
`);

export const CAMPAIGN_STATS = graphql(`
  query campaignStats (
    $campaignId: String!
  ) {
    campaignResource {
      campaignDashboardStats(campaignId: $campaignId) {
        visualizations
        postulations
        priceProducts {
          ...MoneyFields
        }
        payments {
          ...MoneyFields
        }
      }
    }
  }
`);

export const WORK_ORDER_CAMPAIGN = graphql(`
  query workOrderCampaign (
    $campaignId: ID!
    $userId: ID!
  ) {
    campaignResource {
      get(
        id: $campaignId
      ){
        ... CampaignFragment
        hasAmbassadorWorkOrder (ambassadorId: $userId)
        campaignproductsSet {
          ... CampaignProductFragment
          product {
            ... ProductFragment
          }
          relations {
            ... CampaignProductRelationFragment
          }
        }
        preUploadRequirements {
          ... PreUploadRequirementFragment
        }
        campaigncampaignadditionalconditionSet {
          ... CampaignCampaignAdditionalConditionFragment
          condition {
            ... CampaignAdditionalConditionFragment
          }
        }
        contentrequirementSet {
          ... ContentRequirementFragment
          references {
            ... CampaignReferenceFragment
          }
          preUploadRequirements {
            ... PreUploadRequirementFragment
          }
          postType {
            ... PostTypeFragment
          }
        }
      }
      campaignPostulation (
        campaignId: $campaignId
        userId: $userId
      ) {
        ... PostulationFragment
      }
    }
  }
`);

export const CAMPAIGN_OPTIONS = graphql(`
  query campaignOptions(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id_In: [ID]
    $id: ID
    $ambassador_Isnull: Boolean
    $endDate_Gte: Date
    $endDate_Lte: Date
    $endDate_Range: [Date]
    $brand_Id: ID
    $search: String
    $orderBy: String
    $status: MontuCampaignStatusChoices
    $ambassadorIdWorkOrder: ID!
  ) {
    campaignResource {
      list(
        offset: $offset
        before: $before
        after: $after
        first: $first
        last: $last
        id_In: $id_In
        id: $id
        ambassador_Isnull: $ambassador_Isnull
        endDate_Gte: $endDate_Gte
        endDate_Lte: $endDate_Lte
        endDate_Range: $endDate_Range
        brand_Id: $brand_Id
        search: $search
        orderBy: $orderBy
        status: $status
      ) {
        totalCount
        totalPages
        pageInfo {...PageInfoFragment}
        edges {
          cursor
          node {
            ...CampaignFragment
            hasAmbassadorWorkOrder (ambassadorId: $ambassadorIdWorkOrder)
          }
        }
      }
    }
  }
`);
