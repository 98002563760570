import { Box, Typography } from '@mui/material';
import React from 'react';

type Props = {
  title: string
  subtitle?: string
  right?: React.ReactNode
};

const FormTitle = ({
  title,
  subtitle = '',
  right,
}: Props) => (
  <Box>
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Typography variant="h2" color="text.secondary">
        {title}
      </Typography>
      {right}
    </Box>
    <Box mt={1}>
      <Typography color="text.secondary" variant="body1">
        {subtitle}
      </Typography>
    </Box>
  </Box>
);

export default FormTitle;
