import { graphql } from '~graphqlTypeScript';

export const CREATE_CAMPAIGN = graphql(`
  mutation createCampaign(
    $campaign: CampaignInputType!
  ) {
    campaign {
      create(
        campaign: $campaign
      ) {
        campaign {
          id
          brand {
            id
          }
        }
      }
    }
  }
`);

export const FINISH_POSTULATIONS = graphql(`
  mutation finishPostulations(
    $campaignId: String!
  ) {
    campaign {
      finishPostulations(
        campaignId: $campaignId
      ) {
        campaign {
          ... CampaignFragment
        }
      }
    }
  }
`);
export const FINISH_CAMPAIGN = graphql(`
  mutation finishCampaign(
    $campaignId: String!
  ) {
    campaign {
      finishCampaign(
        campaignId: $campaignId
      ) {
        campaign {
          ... CampaignFragment
        }
      }
    }
  }
`);
